<template>
    <div class="w-full flex flex-col bg-gray-50 h-full relative">

        <autralis-success-comp :show="savedFlag"/>
        <autralis-error-comp :show="error" :text="error"/>

        <autralis-modal-comp :modal="modal"/>
        <autralis-loading-table-comp v-if="fetchingUsers.loading" :columns="columns"/>

        <div v-else class="flex flex-col w-full">
            <!--HEADER-->
            <div class="users-header flex w-full p-6 sticky z-10" :style="'top:' + headerTop + 'px'">
                <div class="flex flex-1 w-full">
                    <!-- SMALL SCREENS (<1245px) -->
                    <div class="users-header-sm flex flex-col w-full">
                        <div class="flex justify-between w-full">
                            <!-- BUTTON ADD NEW -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-1"/><span>Add</span>
                                </button>
                            </div>

                            <!-- OTHER BUTTONS DROPDOWN -->
                            <div class="flex items-center justify-center relative">
                                <i class="fal fa-ellipsis-h-alt text-2xl text-gray-500 hover:text-blue-700 transition ease-in-out duration-150 cursor-pointer" @click="menuOpen = !menuOpen"/>
                                <div v-if="menuOpen" class="bg-white absolute right-0 z-10 rounded-md shadow-lg p-4 dropdown-nav" style="">
                                    <div v-if="users && users.length > 0" class="flex items-center mb-4 cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>

                                    <div class="flex items-center">
                                        <div class="flex cursor-pointer text-gray-500 hover:text-gray-800" @click="sendInvitationPrompt">
                                            <div v-if="sendInvitation.saving" class="flex items-center">
                                                <span class="fas fa-spinner-third fa-spin mr-2"></span>
                                                <translate>Saving</translate>
                                                ...
                                            </div>
                                            <div v-else><i class="fal fa-paper-plane mr-2"/>Send invitation</div>
                                        </div>
                                    </div>


                                    <div v-if="selected.length > 0" class="flex items-center mt-4">
                                        <autralis-company-user-reset-password-comp
                                            :company-id="$props.companyId"
                                            :user-ids="userIds"
                                            @on-success="selected = [];selectAll = false;savedFlag=true"
                                        >
                                            <div class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                                <div><i class="fal fa-lock-alt mr-2"/>Reset password</div>
                                            </div>
                                        </autralis-company-user-reset-password-comp>
                                    </div>


                                    <div v-if="selected.length > 0 && stateTriggers.length > 0" class="flex flex-shrink-0 mt-4">
                                        <autralis-user-status-button-comp
                                            :company-id="$props.companyId"
                                            :user-ids="userIds"
                                            @on-success="selected = [];selectAll = false;savedFlag=true"
                                            :classNameConfig="{
                                            'wrapper': 'flex flex-col items-start',
                                            'button': 'flex items-center cursor-pointer text-gray-500 hover:text-gray-800',
                                            'nextButton': 'ml-0 mt-4'
                                        }"
                                        />
                                    </div>

                                    <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 mt-4">
                                        <autralis-delete-user-comp :user-ids="userIds"
                                                                   :company-id="$props.companyId"
                                                                   :page="page"
                                                                   :itemsPerPage="itemsPerPage"
                                                                   @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- FILTERS -->
                        <div class="flex mt-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="false" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>


                    <!-- NORMAL SCREENS (>=1245px and <1450) -->
                    <div class="users-header-base flex flex-col w-full">
                        <div class="flex w-full">
                            <!-- BUTTON ADD NEW -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-1"/><span>Add</span>
                                </button>
                            </div>

                            <!-- OTHER BUTTONS DROPDOWN -->
                            <div class="flex">
                                <div class="flex items-center">
                                    <div v-if="users && users.length > 0" class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>
                                </div>


                                <div class="flex items-center border-l border-gray-200 pl-4 ml-4">
                                    <div class="flex cursor-pointer text-gray-500 hover:text-gray-800" @click="sendInvitationPrompt">
                                        <div v-if="sendInvitation.saving" class="flex items-center">
                                            <span class="fas fa-spinner-third fa-spin mr-2"></span>
                                            <translate>Saving</translate>
                                            ...
                                        </div>
                                        <div v-else><i class="fal fa-paper-plane mr-2"/>Send invitation</div>
                                    </div>
                                </div>


                                <div v-if="selected.length > 0" class="flex items-center border-l border-gray-200 pl-4 ml-4">
                                    <autralis-company-user-reset-password-comp
                                        :user-ids="userIds"
                                        :company-id="$props.companyId"
                                        @on-success="selected = [];selectAll = false;savedFlag=true">
                                        <div class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                            <div><i class="fal fa-lock-alt mr-2"/>Reset password</div>
                                        </div>
                                    </autralis-company-user-reset-password-comp>
                                </div>


                                <div v-if="selected.length > 0 && stateTriggers.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 ml-4">
                                    <autralis-user-status-button-comp :user-ids="userIds"
                                                                      :company-id="$props.companyId"
                                                                      @on-success="selected = [];selectAll = false;savedFlag=true"
                                                                      :classNameConfig="{
                                                                      'wrapper': 'flex',
                                                                        'button': 'flex items-center cursor-pointer text-gray-500 hover:text-gray-800 ml-0 md:ml-4',
                                                                        'nextButton': 'mt-0'
                                                                  }"
                                    />
                                </div>

                                <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 pl-4 ml-4">
                                    <autralis-delete-user-comp :user-ids="userIds"
                                                               :company-id="$props.companyId"
                                                               :page="page"
                                                               :itemsPerPage="itemsPerPage"
                                                               @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                </div>
                            </div>
                        </div>

                        <!-- FILTERS -->
                        <div class="flex mt-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="false" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>


                    <!-- BIG SCREENS (>=1450px) -->

                    <div class="users-header-lg flex justify-between w-full">
                        <div class="flex flex-1">
                            <!-- ADD NEW BUTTON -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-0 md:mr-1"/>
                                    <span class="hidden md:block">Add</span>
                                </button>
                            </div>
                            <!-- OTHER BUTTONS -->
                            <div class="flex">
                                <div class="flex items-center">
                                    <div v-if="users && users.length > 0" class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>
                                </div>


                                <div class="flex items-center border-l border-gray-200 pl-4 ml-4">
                                    <div class="flex cursor-pointer text-gray-500 hover:text-gray-800" @click="sendInvitationPrompt">
                                        <div v-if="sendInvitation.saving" class="flex items-center">
                                            <span class="fas fa-spinner-third fa-spin mr-2"></span>
                                            <translate>Saving</translate>
                                            ...
                                        </div>
                                        <div v-else><i class="fal fa-paper-plane mr-2"/>Send invitation</div>
                                    </div>
                                </div>


                                <div v-if="selected.length > 0" class="flex items-center border-l border-gray-200 pl-4 ml-4">
                                    <autralis-company-user-reset-password-comp
                                        :user-ids="userIds"
                                        :company-id="$props.companyId"
                                        @on-success="selected = [];selectAll = false;savedFlag=true">
                                        <div class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                            <div><i class="fal fa-lock-alt mr-2"/>Reset password</div>
                                        </div>
                                    </autralis-company-user-reset-password-comp>
                                </div>


                                <div v-if="selected.length > 0 && stateTriggers.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 ml-4">
                                    <autralis-user-status-button-comp :user-ids="userIds"
                                                                      :company-id="$props.companyId"
                                                                      @on-success="selected = [];selectAll = false;savedFlag=true"
                                                                      :classNameConfig="{
                                                                        'button': 'flex items-center cursor-pointer text-gray-500 hover:text-gray-800 ml-0 md:ml-4'
                                                                  }"
                                    />
                                </div>

                                <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 pl-4 ml-4">
                                    <autralis-delete-user-comp :user-ids="userIds"
                                                               :company-id="$props.companyId"
                                                               :page="page"
                                                               :itemsPerPage="itemsPerPage"
                                                               @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                </div>
                            </div>
                        </div>


                        <!-- FILTERS -->
                        <div class="flex">

                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="true" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="users-content flex flex-1 overflow-y-auto mb-6">
                <div class="align-middle inline-block min-w-full overflow-hidden px-6">

                    <!--                <div class="z-0 overflow-x-auto bg-white rounded-lg overflow-y-auto relative" :style="{height: tableHeight  + 'px'}">-->
                    <div class="z-0 overflow-x-auto bg-white rounded-lg overflow-y-auto relative border border-gray-200">
                        <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                            <tr class="text-left">
                                <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-white" style="width: 20px">
                                    <label class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" v-model="selectAll">
                                    </label>
                                </th>
                                <th class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">Edit</th>
                                <th v-for="col in columns" :key="col.id" :style="{minWidth: col.width + 'px' }"
                                    class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">{{ col.title }}
                                </th>
                            </tr>
                            </thead>

                            <tbody v-if="users.length>0">

                            <tr v-for="item in users"
                                :key="item.id"
                                :class="[
                                'text-gray-500 hover:bg-blue-500 hover:text-white cursor-pointer',
                                 selected.includes(item.id) && 'bg-blue-50',
                                 currentlyActive === item.id && 'bg-blue-500 text-white'
                                 ]">
                                <td class="border-dashed border-t border-gray-200 px-3" style="width: 20px">
                                    <label class="inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox"
                                               class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                               :checked="selected.includes(item.id)"
                                               @click.stop="handleSelectCheckbox(item.id)">
                                    </label>
                                </td>
                                <td class="border-dashed border-t border-gray-200 px-3 cursor-pointer underline"
                                    style="min-width: 60px"
                                    @click="edit(item)">
                                    Edit
                                </td>

                                <td v-for="col in columns" :key="col.id" :style="{minWidth: col.width + 'px' }" class="border-dashed border-t border-gray-200 select-none"
                                    @click.stop="handleClick(item, $event)">
                                    <component :is="col.component" :column="col.id" :item="item" @select-item="select"/>
                                </td>
                            </tr>
                            </tbody>


                            <tbody v-else>
                            <tr>
                                <td :colspan="Object.keys(columns).length + 2">
                                    <div v-if="!fetchingUsers.loading && fetchingUsers.error" class="flex rounded-md bg-red-50 p-4 items-center m-6">
                                        <i class="fal fa-exclamation-circle text-red-400 mr-3"/>
                                        <h3 class="text-sm font-medium text-red-800">{{ fetchingUsers.error }}</h3>
                                    </div>
                                    <div v-else class="flex flex-row justify-center items-center p-2 font-bold text-2xl">No records found</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

            <div v-if="!fetchingUsers.loading && $store.getters['master/totalCompanyUsers'] > itemsPerPage"
                 class="px-6 py-2 w-full h-14 static bottom-0 bg-gray-50 text-gray-800 left-0 right-0">
                <autralis-addresses-paginator-comp
                    :currentPage="page"
                    :recordsPerPage="itemsPerPage"
                    :totalCount="$store.getters['master/totalCompanyUsers']"
                    @on-change-page="onPageChange"
                />
            </div>
        </div>
    </div>

</template>

<script>
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import comboButton from '@/components/combo-button'
import BaseCol from '../../columns/base-column'
import BaseColCountry from '../../columns/base-column-country'
import BaseColCheck from '../../columns/base-column-check'
import BaseColState from '../../columns/base-column-state'
import BaseColMultipleItems from '../../columns/base-column-multiple-items'
import Paginator from './components/Paginator'
import Modal from "@/components/modal";
import ResetPassword from './components/ResetPassword';
import {modalInfo} from "@/common/modal";

import {filter, find, groupBy, isBoolean, isEmpty, isInteger, map, orderBy, sortBy} from "lodash";
import {handleFetchPaginatedUsers} from "./handlers";
import UserStatusButtons from "./components/UserStatusButtons";
import DeleteUser from "./components/DeleteUser";
import {DETAIL_PANEL_KEYS, HOME_LIST_ITEMS_PER_PAGE} from "../../../constants";
import {generateTriggers, STATES} from "../states-and-triggers";
import LoadingTable from "@/components/loading-table";

const defaultColumns = {
    title: {width: 50, id: 'title', title: 'Title', component: 'autralis-base-col-comp', defaultSort: false},
    firstName: {width: 150, id: 'firstName', title: 'First name', component: 'autralis-base-col-comp', defaultSort: true},
    lastName: {width: 150, id: 'lastName', title: 'Last name', component: 'autralis-base-col-comp', defaultSort: false},
    email: {width: 240, id: 'email', title: 'Email', component: 'autralis-base-col-comp', defaultSort: false},
    telephone: {width: 150, id: 'telephone', title: 'Telephone', component: 'autralis-base-col-comp', defaultSort: false},
    mobile: {width: 150, id: 'mobile', title: 'Mobile', component: 'autralis-base-col-comp', defaultSort: false},
    roles: {width: 240, id: 'roles', title: 'Roles', component: 'autralis-base-col-multiple-items-comp', defaultSort: false},
    status: {width: 150, id: 'state', title: 'State', component: 'autralis-base-col-state-comp', defaultSort: false}
};

export default {
    name: "Users",
    props: {
        companyId: {type: Number}
    },
    data() {
        return {
            columns: defaultColumns,
            views: [
                {
                    id: 'general',
                    title: 'General',
                    columns: defaultColumns
                }
            ],
            savedFlag: null,
            sortItem: null,
            selectAll: false,
            sortFlag: false,
            tableHeight: window.innerHeight - 206,
            selectedId: null,
            selected: [],
            fetchingUsers: {
                loading: false,
                error: null
            },
            error: null,
            sendInvitation: {
                saving: false
            },
            sort: null,
            //Pagination
            page: 1,
            itemsPerPage: HOME_LIST_ITEMS_PER_PAGE,
            totalCount: null,
            deleting: false,
            modal: modalInfo(),
            stateTriggers: [],
            numClicks: 0,
            clickTimer: null,
            currentlyActive: null,
            menuOpen: false,
            headerTop: 0
        }
    },

    watch: {
        selectAll(val) {
            this.selected = val ? this.users.map(i => i.id) : []
        },
        sort() {
            this.updateCompanyUsers(this.$store.getters['master/companyUsers'])
        },

        selected(newSelected) {
            const selectedUsers = filter(this.users, user => newSelected.includes(user.id));
            const states = Object.keys(groupBy(selectedUsers, 'state')).map(key => key);
            this.stateTriggers = generateTriggers(states)
        },
        '$store.state.master.detail': function () {
            const storeDetail = this.$store.state.master.detail;
            if (storeDetail) {
                if (storeDetail['key'] && storeDetail['key'] === DETAIL_PANEL_KEYS.EDIT_COMPANY_USER) {
                    this.currentlyActive = storeDetail['id']
                }
            } else {
                this.currentlyActive = null
            }
        },
        companyId(newCompanyId) {
            this.fetchUsers(newCompanyId);
        }
    },

    computed: {
        users() {
            return this.sortedItems(this.$store.getters['master/companyUsers']);
        },
        userIds() {
            const selectedUsers = filter(this.users, user => this.selected.includes(user.id))
            return map(selectedUsers, user => user.userId)
        },

        convertToXlsxData() {
            let fileName = 'users';
            if (this.$store.getters['master/company'].id === this.$props.companyId) {
                fileName = this.$store.getters['master/company'].name + ' users'
            }

            const company = find(this.$store.getters['master/suppliers'], s => s.id === this.$props.companyId);
            if (company) {
                fileName = company.name + ' users'
            }

            const sheetName = 'users'
            const columns = [
                {label: "Title", field: "title"},
                {label: "First name", field: "firstName"},
                {label: "Last name", field: "lastName"},
                {label: "Email", field: "email"},
                {label: "Telephone", field: "telephone"},
                {label: "Mobile", field: "mobile"},
                {label: "Roles", field: "roles"},
                {label: "State", field: "state"},
            ]

            const ordered = orderBy(this.$store.getters['master/companyUsers'], ['firstName'], ['asc'])
            const mappedData = map(ordered, user => {
                return {
                    'title': user.title,
                    'firstName': user.firstName,
                    'lastName': user.lastName,
                    'email': user.email,
                    'telephone': user.telephone,
                    'mobile': user.mobile,
                    'roles': (map(user.roles, r => r.name)).join(', '),
                    'state': STATES[user.state].label,
                }
            })

            return {
                columns: columns,
                data: mappedData,
                fileName,
                sheetName
            }
        }
    },

    methods: {
        onResize() {
            if (window.innerWidth < 1024) {
                this.headerTop = 157;
            } else {
                this.headerTop = 0
            }
        },
        setInitSort() {
            const item = find(this.selectColumns(), col => col.defaultSort);
            if (item) this.selectSort(item);
        },

        sortedItems(items) {
            let results = items;
            if (!this.sort) return results;

            if (isBoolean(items[this.sort.field]) || isInteger(items[this.sort.field])) {
                results = sortBy(items, [this.sort.field])
            } else {
                results = orderBy(items, [this.sort.field], [this.sort.type])
            }
            return results;
        },

        selectColumns() {
            return Object.keys(this.columns).map(c => {
                return {
                    item: c,
                    name: this.columns[c].title,
                    key: this.columns[c].id,
                    counter: null,
                    icon: null,
                    separator: false,
                    defaultSort: this.columns[c].defaultSort
                }
            });
        },

        handleSelectCheckbox(id) {
            let newSelected = null;
            if (find(this.selected, item => item === id)) {
                newSelected = filter(this.selected, item => item !== id);
            } else {
                newSelected = [...this.selected, id]
            }
            this.selected = newSelected
        },

        edit(item) {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_COMPANY_USER,
                companyId: this.$props.companyId,
                userId: item.id,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        singleRowClick(item) {
            this.handleSelectCheckbox(item.id)
        },
        doubleRowClick(item) {
            this.edit(item);
        },

        handleClick(item, e) {
            e.preventDefault()
            this.numClicks++;

            if (this.numClicks === 1) {
                this.clickTimer = setTimeout(() => {
                    this.numClicks = 0;
                    this.singleRowClick(item, e);
                }, 200);
            } else if (this.numClicks === 2) {
                clearTimeout(this.clickTimer);
                this.numClicks = 0;
                this.doubleRowClick(item, e);
            }
        },

        addNew() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_COMPANY_USER,
                companyId: this.$props.companyId,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        select(item) {
            console.log("SELECT");
            console.log(item);
            // this.$emit('select-item', item)
            // this.$store.commit('seller/openItem', 0);
        },

        setSortType() {
            this.sort = {...this.sort, type: this.sort.type === 'asc' ? "desc" : "asc"}
        },

        selectSort(item) {
            this.sort = {field: item.key, title: item.name, type: 'asc'}
        },

        updateCompanyUsers(items) {
            this.$store.commit('master/setCompanyUsers', this.sortedItems(items))
        },

        onPageChange(page) {
            this.page = page;
            this.fetchUsers(this.$props.companyId)
        },

        setOrder(field) {
            const existing_order = !isEmpty(this.sort);
            if (existing_order) {
                // Change type (ASC or DESC)
                this.sort = {
                    ...this.sort,
                    type: this.sort.type === 'asc' ? 'desc' : 'asc'
                }
            } else {
                // Add new order
                this.sort = {
                    field: field,
                    type: 'desc'
                }
            }
        },


        async sendInvitationPrompt() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.SEND_INVITATIONS,
                companyId: this.$props.companyId
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        async fetchUsers(companyId) {
            if (companyId) {
                try {
                    this.fetchingUsers.loading = true;
                    this.fetchingUsers.error = null;

                    const payload = {
                        companyId: companyId,
                        page: this.page,
                        itemsPerPage: this.itemsPerPage
                    }
                    console.log("PAYLOAD: ", payload)
                    await handleFetchPaginatedUsers(payload, (response) => {
                        this.page = response.page;
                        this.updateCompanyUsers(response.users);
                    }, () => {
                        this.fetchingUsers.error = 'Loading company users failed. Please try again.'
                    })

                    this.fetchingUsers.loading = false

                } catch (err) {
                    this.fetchingUsers.error = 'Loading company users failed. Please try again.'
                    this.fetchingUsers.loading = false
                }
            } else {
                this.fetchingUsers.error = 'No company ID provided. '
            }
        },


    },

    components: {
        'autralis-combo-button-comp': comboButton,
        'autralis-base-col-comp': BaseCol,
        'autralis-base-col-country-comp': BaseColCountry,
        'autralis-base-col-check-comp': BaseColCheck,
        'autralis-base-col-state-comp': BaseColState,
        'autralis-base-col-multiple-items-comp': BaseColMultipleItems,
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
        'autralis-addresses-paginator-comp': Paginator,
        'autralis-modal-comp': Modal,
        'autralis-company-user-reset-password-comp': ResetPassword,
        'autralis-delete-user-comp': DeleteUser,
        'autralis-user-status-button-comp': UserStatusButtons,
        'autralis-loading-table-comp': LoadingTable,
    },

    created() {
        window.addEventListener("resize", this.onResize);
        this.setInitSort();
        this.fetchUsers(this.$props.companyId);
        this.onResize();
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
}
</script>

<style scoped>
.dropdown-nav {
    top: 120%;
    min-width: 150px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.users-header, .users-content {
    background: rgba(250, 250, 250, 1);
}

.users-header-sm, .users-header-base, .users-header-lg {
    display: none;
}

@media (max-width: 1245px) {
    .users-header-sm {
        display: flex;
    }
}

@media (min-width: 1245px) and (max-width: 1450px) {
    .users-header-base {
        display: flex;
    }
}

@media (min-width: 1450px) {
    .users-header-lg {
        display: flex;
    }
}

@media (max-width: 767px) {
    .users-header, .users-content {
        background: white;
    }
}
</style>