<template>
    <div class="px-2 py-1">
        <div v-for="state in userStates" :key="state.key">
            <div v-if="item[column] === state.key" :class="'flex items-center text-left text-' + state.color +'-500 truncate font-semibold uppercase tracking-wider'">
                <i :class="[state.icon, 'ml-1 mr-2']" /> {{state.label}}
            </div>
        </div>
    </div>
</template>

<script>

import {STATES} from "../Home/states-and-triggers";

export default {
    name: "base-column-state",
    props: {
        'column': { type: String },
        'item': { type: Object }
    },
    computed: {
        userStates(){
            return Object.keys(STATES).map(key => {
                return {
                    key: key,
                    label: STATES[key].label,
                    color: STATES[key].color,
                    icon: STATES[key].icon
                }
            });
        }
    }
}
</script>

<style scoped>

</style>