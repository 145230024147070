<template>
    <div class="px-2 py-1 relative">
        <div v-if="!items || items.length < 1" class="text-gray-400 text-xs">Nothing to show.</div>
        <div v-else class="flex items-center text-left">
            <div v-for="(item, index) in visibleItems" :key="item.id">
                {{ item.name }}<span v-if="visibleItems.length - 1 > index" class="mr-1">,</span>
            </div>
            <i v-if="showMoreButton" :class="['fal ml-2 text-blue-600 cursor-pointer', open ? 'fa-angle-double-up' : 'fa-angle-double-down']" @click.stop="open = !open"/>
        </div>
        <div v-if="open" class="flex flex-col">
            <div v-for="item in otherItems" :key="item.id">
                {{ item.name }}
            </div>
        </div>

    </div>
</template>

<script>
const VISIBLE_ITEMS = 2
export default {
    name: "base-column-multiple-items",
    data() {
        return {
            open: false
        }
    },
    props: {
        'column': {type: String},
        'item': {type: Object}
    },
    computed: {
        showMoreButton() {
            return this.items.length > VISIBLE_ITEMS
        },

        items() {
            return this.$props.item[this.$props.column];
        },
        visibleItems() {
            return this.items.slice(0, VISIBLE_ITEMS);
        },
        otherItems() {
            return this.items.slice(VISIBLE_ITEMS, this.items.length);
        }
    }
}
</script>

<style scoped>

</style>