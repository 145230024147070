<template>
    <div>
        <autralis-error-comp :show="error !== null" :text="error"/>
        <autralis-success-comp :show="savedFlag === true"/>
        <autralis-modal-comp :modal="modal"/>
        <div @click="resetPasswordPrompt">
            <div v-if="saving" class="flex items-center">
                <span class="fas fa-spinner-third fa-spin mr-2"></span>
                <translate>Saving</translate>
                ...
            </div>
            <slot v-else/>
        </div>
    </div>
</template>

<script>
import {handleResetUserPassword} from "../handlers";
import Modal from "@/components/modal";
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import {modalInfo} from "@/common/modal";

export default {
    name: "ResetPassword",
    props: {
        userIds: {
            type: Array,
            default: () => []
        },
        companyId: {
            type: Number
        }
    },
    data() {
        return {
            modal: modalInfo(),
            saving: false,
            savedFlag: null,
            error: null,
            ids: []
        }
    },


    methods: {
        async resetPasswordPrompt() {
            const userIds = this.ids;
            this.modal.color = 'blue';
            const msg = userIds.length > 1
                ? "Are you sure that you want to reset password for those " + userIds.length + ' users and send the new credentials?'
                : "Are you sure that you want to reset password and send new credentials?";

            this.modal.view('Reset password', msg, 'Confirm', 'exclamation-triangle', this.handleResetPassword)
        },

        async handleResetPassword() {
            try {
                this.saving = true;
                this.savedFlag = null;
                this.error = null

                const payload = {
                    companyId: this.$props.companyId,
                    userIds: this.ids
                };
                // eslint-disable-next-line no-unused-vars
                await handleResetUserPassword(payload, (response) => {
                    this.$emit('on-success', response)
                    // eslint-disable-next-line no-debugger
                    this.savedFlag = true;

                }, (error) => {
                    this.error = error
                })

                this.saving = false;

            } catch (err) {
                this.error = 'The reset password could not be done. Please try again.'
                this.saving = false;
            }
        },
    },

    created() {
        this.ids = this.$props.userIds
    },

    components: {
        'autralis-modal-comp': Modal,
        'autralis-error-comp': ErrorBox,
        'autralis-success-comp': SuccessBox,
    }
}
</script>

<style scoped>

</style>