<template>
    <div>
        <autralis-error-comp :show="error !== null" :text="error"/>
        <autralis-success-comp :show="savedFlag === true"/>
        <autralis-modal-comp :modal="modal"/>
        <div class="flex cursor-pointer text-red-500 hover:text-red-800 font-medium" @click="deletePrompt">
            <div v-if="deleting" class="flex items-center">
                <span class="fas fa-spinner-third fa-spin mr-2"></span>
                <translate>Deleting</translate>
                ...
            </div>
            <div v-else><i class="fal fa-trash mr-2"/>Delete</div>
        </div>
    </div>
</template>

<script>
import {handleDeleteUsers} from "../handlers";
import Modal from "@/components/modal";
import {modalInfo} from "@/common/modal";
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import {map} from "lodash";
import {HOME_LIST_ITEMS_PER_PAGE} from "../../../../constants";
import {STATE_TRIGGERS} from "../../states-and-triggers";

export default {
    name: "DeleteUser",
    props: {
        userIds: {
            type: Array,
            default: () => []
        },
        companyId: {
            type: Number
        },
        page: {
            type: Number,
            default: 1
        },
        itemsPerPage: {
            type: Number,
            default: HOME_LIST_ITEMS_PER_PAGE
        }
    },

    data() {
        return {
            deleting: false,
            modal: modalInfo(),
            error: null,
            savedFlag: null
        }
    },

    methods: {
        deletePrompt() {
            const msg = this.$props.userIds.length > 1
                ? "You can not delete those " + this.$props.userIds.length + ' users. You can only LOCK them. Do you want to do that?'
                : "You can not delete user. You can only LOCK him. Do you want to do that?";

            this.modal.view('Confirm', msg, 'Confirm', 'exclamation-triangle', this.handleDelete)
        },

        async handleDelete() {
            try {
                this.deleting = true;
                this.savedFlag = null;
                this.error = null


                const usersWithTriggers = map(this.$props.userIds, userId => ({
                    userId: userId,
                    trigger: STATE_TRIGGERS.LOCK
                }))

                const payload = {
                    companyId: this.$props.companyId,
                    users: usersWithTriggers,
                    page: this.$props.page,
                    recordsPerPage: this.$props.itemsPerPage
                };

                // eslint-disable-next-line no-unused-vars
                await handleDeleteUsers(payload, (response) => {
                    this.$emit('on-success', response)
                    this.savedFlag = true;
                }, (error) => {
                    this.error = error
                })

                this.deleting = false;

            } catch (err) {
                console.log(err)
                this.error = 'The company user could not be locked. Please try again.'
                this.deleting = false;
            }
        },
    },

    components: {
        'autralis-modal-comp': Modal,
        'autralis-error-comp': ErrorBox,
        'autralis-success-comp': SuccessBox,
    }
}
</script>

<style scoped>

</style>