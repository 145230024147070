<template>
    <autralis-status-buttons :classNameConfig="classNameConfig"
                             :states="states"
                             :saving="saving"
                             :error="error"
                             @on-change="changeStatus"/>
</template>

<script>
import StatusButtons from "../../../../components/StatusButtons";
import {filter, map, uniq} from "lodash";
import {handleChangeUsersStatus} from "../handlers";

export default {
    name: "UserStatusButtons",
    props: {
        userIds: {
            type: Array,
            default: () => []
        },
        companyId: {
            type: Number
        },
        classNameConfig: {
            type: Object,
            default: () => {
            }
        }
    },

    data() {
        return {
            saving: false,
            error: null
        }
    },

    computed: {
        users() {
            return this.$store.getters['master/companyUsers']
        },

        states() {
            return uniq(map(filter(this.users, user => this.$props.userIds.includes(user.userId)), user => user.state))
        }
    },

    methods: {
        async changeStatus(trigger) {
            try {
                this.saving = true;
                this.error = null

                const mappedUsers = map(this.$props.userIds, userId => ({
                    userId: userId,
                    trigger: trigger
                }))

                const payload = {
                    companyId: this.$props.companyId,
                    users: mappedUsers
                };
                await handleChangeUsersStatus(payload, (response) => {
                    this.$emit('on-success', response)
                }, (error) => {
                    this.error = error
                })

                this.saving = false;

            } catch (err) {
                this.error = 'The company user status could not be changed. Please try again.'
                this.saving = false;
            }
        },
    },

    components: {
        'autralis-status-buttons': StatusButtons
    }

}
</script>

<style scoped>

</style>